import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrescreenService {

  constructor(
    private _http:HttpClient
  ) { }

  addPrescreenDetails(prescreenInfo: any, remote = '') {
    return new Promise((resolve, reject) => {
      let sUrl = `/api/salespro/prescreen`;
      if (remote === 'remote') {
        sUrl = `/api/salespro/prescreen/remote`;
      }
      return this._http.post(sUrl, prescreenInfo).subscribe((response: any) => {
        resolve(response);
      }, reject);
    });
  }
  updatePrescreenDeatils(customerOdooId,updatedContent:any,remote = ''){
    let sUrl = `/api/salespro/prescreen/${customerOdooId}`;
    if (remote === 'remote') {
      sUrl = `/api/salespro/prescreen/remote/${customerOdooId}`;
    }
    return this._http.patch(sUrl, updatedContent).toPromise();

  }
}
