export class Customer {
  id?: number;
  guid?: string;
  streetNumber?: string;
  streetName?: string;
  streetType?: string;
  streetUnit?: string;
  streetTwo?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  firstName?: string;
  lastName?: string;
  birthDate?: string;
  email?: string;
  emailType?: string;
  phone?: string;
  phoneType?: string;
  package?: string;
  monthlyPayment?: number;
  integrationFee?: number;
  leadType?: string;
  products?: string;

  addOns?: string;
  video?: string;
  status?: string;
  salesperson?: number;
  createdAt?: string;
  updatedAt?: string;
  lendProAccountStatus?: string;

  odooId?: number;
  referred?: string;

  propertyType?: string;

  creditScore?: number;
  paymentAmount?: number;
  creditFee?: number;

  financeAmount?: number;

  approvedBuilder?: boolean;
  userId?: number;
  userEmail?: string;

  userName?: string;
  isRemote?: boolean;
  isPaid?: boolean;
  isCustomerSigned?: boolean;
  remotePaymentType?: string;
  accountId?: string;
  envelopeId?: string;
  totalTax?: number;
  mailMonthlyPayment?: number;
  mailTotal?: number;
  isSkipped?: boolean;
  laborTotal?: number;
  previousAddress?: string;
  isPreviousAddressAdded?: boolean;
  isLoanApproved?: boolean;

  rmrAmount?: string;
  numberOfMonths?: string;
  loanAmount?: string;
  isMailSent?: boolean;
  documentId?: string;
  isLendproAttempted?: boolean;
  submittedFortivaLoanAmount?: number;
  fortivaProducts?: string;
  lendproResponse?: string;
  paymentId?: string;
  creditLimit?: number;
  lendProStatus?: string;
  display_name?: string;
  calendlyGenerated?: number;
  calendlyUrl?: string;
  source?: boolean;
  calendlyRetry?: number;
  isDemo?: boolean;
  isShowroom?: boolean;
  showRoomMonthlyTerm?: string;
  partner_id?: number;
  taskId?: string;
  taskScheduleFailMessage?: string;
  taskScheduleTitle: string;
  isTaskScheduled: boolean;
  taskScheduleDate?: string;
  isRenewal?: boolean;
  isReset?: boolean;
  resetOn?: string;
  timezoneOffset?: number;
  docusignPrefillInfo?: string;
  docusignPaymentInfo?: string;
  payment_data?: string;
  docusign_prefill_time?: string;
  summaryDocumentId?: string;
  isPhoneSales?: boolean;
  projected_close_date?: string;
  isIntroductoryOffer?: boolean;
  initialStageId?: number;
  paymentFailureMessage?: string;
  schedulerType?: string;
  restartPaymentAmount?: number;
  paidAmount?: number;
  docuMonitoring?: string;
  docuMonthlyTax?: string;
  docuTotalRmr?: string;
  docuPaymentViaAci?: string;
  docuPaymentViaLendPro?: string;
  docuAdditionalEquipment?: string;
  docuAddOnTotal?: number;
  docuPlanName?: string;
  payment_type?: string;
  is_ext_verified?: boolean;
  state_code?: string;
  branch?: string;
  isHomeOwner?: boolean;
  isNewGenFlow?: boolean;
  odooSchedulerErrorCount?: number;
  isRemoveRepSign?: boolean;
  isAmex?: boolean;
  amexServiceFee?: number;
  fundingAccountSubType?: string;
  fundingAccount?: string;
  billingAccountNumber?: number;
  fullPaymentApplied?: boolean;
  paymentDate?: string;
  customerSecondaryInfo?: string;
  equifaxCreditScore?: number;
  branchName?: string;
  fullAddress?: string;
  loanType?: string;
  lendfoundryRequestedAmt?: string;
  loanAppCid?: string;
  odooApplicationId?: string;
  lfLoanAmountSubmitted?: boolean;
  lfAttempted?: boolean;
  remoteLendfoundryInfo?: string;
  submittedLendfoundryLoanAmount?: number;
  rmrListPrice?: number;
  rmrSalePrice?: number;
  isRmrIncludedInPayment?: boolean;
  paymentResultInfo?: string;
  signData?: string;
  mobile?: string;
  formattedPhone?: string;
  isVirtualAppointment?: boolean;
  isRemoteAciPaymentDone?: boolean;
  markAsPaidInfo?: string;

  isOnitHome?: boolean;

  isConvenienceFeeAdded?: boolean;
  showFireProtection?: boolean;
  includeTax?: boolean;
  isCreditFrozen?: boolean;


  creditCheckId?:string;
  ownerType?:string;
  loanApplicationsActions?:string;
  preScreenId?: number;
  preScreenCreatedAt?: string;
  preScreenStatus?: string;
  preApprovedAmount?: number;
  preScreenProvider?: string;
  preScreenRejected?:boolean;
  // Constructor
  constructor(item) {
    this.update(item);
  }

  // Update the model
  update(item) {
    if (!item) {
      return;
    }

    if (item.id !== undefined) {
      this.id = item.id;
    }
    if (item.guid !== undefined) {
      this.guid = item.guid;
    }
    if (item.streetNumber !== undefined) {
      this.streetNumber = item.streetNumber;
    }
    if (item.streetName !== undefined) {
      this.streetName = item.streetName;
    }
    if (item.streetType !== undefined) {
      this.streetType = item.streetType;
    }
    if (item.streetTwo !== undefined) {
      this.streetTwo = item.streetTwo;
    }
    if (item.streetUnit !== undefined) {
      this.streetUnit = item.streetUnit;
    }
    if (item.city !== undefined) {
      this.city = item.city;
    }
    if (item.state !== undefined) {
      this.state = item.state;
    }
    if (item.zipCode !== undefined) {
      this.zipCode = item.zipCode;
    }
    if (item.firstName !== undefined) {
      this.firstName = item.firstName;
    }
    if (item.lastName !== undefined) {
      this.lastName = item.lastName;
    }
    if (item.birthDate !== undefined) {
      this.birthDate = item.birthDate;
    }
    if (item.email !== undefined) {
      this.email = item.email;
    }
    if (item.emailType !== undefined) {
      this.emailType = item.emailType;
    }
    if (item.phone !== undefined) {
      this.phone = item.phone;
    }
    if (item.phoneType !== undefined) {
      this.phoneType = item.phoneType;
    }
    if (item.package !== undefined) {
      this.package = item.package;
    }
    if (item.monthlyPayment !== undefined) {
      this.monthlyPayment = item.monthlyPayment;
    }
    if (item.integrationFee !== undefined) {
      this.integrationFee = item.integrationFee;
    }
    if (item.leadType !== undefined) {
      this.leadType = item.leadType;
    }
    if (item.products !== undefined) {
      this.products = item.products;
    }
    if (item.addOns !== undefined) {
      this.addOns = item.addOns;
    }
    if (item.video !== undefined) {
      this.video = item.video;
    }
    if (item.status !== undefined) {
      this.status = item.status;
    }
    if (item.salesperson !== undefined) {
      this.salesperson = item.salesperson;
    }
    if (item.odooId !== undefined) {
      this.odooId = item.odooId;
    }
    if (item.referred !== undefined) {
      this.referred = item.referred;
    }

    if (item.propertyType !== undefined) {
      this.propertyType = item.propertyType;
    }

    if (item.creditScore !== undefined) {
      this.creditScore = item.creditScore;
    }

    if (item.paymentAmount !== undefined) {
      this.paymentAmount = item.paymentAmount;
    }

    if (item.creditFee !== undefined) {
      this.creditFee = item.creditFee;
    }

    if (item.financeAmount !== undefined) {
      this.financeAmount = item.financeAmount;
    }

    if (item.approvedBuilder !== undefined) {
      this.approvedBuilder = item.approvedBuilder;
    }
    if (item.userId !== undefined) {
      this.userId = item.userId;
    }
    if (item.userEmail !== undefined) {
      this.userEmail = item.userEmail;
    }
    if (item.userName !== undefined) {
      this.userName = item.userName;
    }
    if (item.isRemote !== undefined) {
      this.isRemote = item.isRemote;
    }
    if (item.lendProAccountStatus !== undefined) {
      this.lendProAccountStatus = item.lendProAccountStatus;
    }
    if (item.lendProAccountStatus !== undefined) {
      this.lendproResponse = item.lendproResponse;
    }
    if (item.isPaid !== undefined) {
      this.isPaid = item.isPaid;
    }
    if (item.isCustomerSigned !== undefined) {
      this.isCustomerSigned = item.isCustomerSigned;
    }
    if (item.remotePaymentType !== undefined) {
      this.remotePaymentType = item.remotePaymentType;
    }
    if (item.accountId !== undefined) {
      this.accountId = item.accountId;
    }
    if (item.envelopeId !== undefined) {
      this.envelopeId = item.envelopeId;
    }

    if (item.totalTax !== undefined) {
      this.totalTax = item.totalTax;
    }
    if (item.mailMonthlyPayment !== undefined) {
      this.mailMonthlyPayment = item.mailMonthlyPayment;
    }
    if (item.mailTotal !== undefined) {
      this.mailTotal = item.mailTotal;
    }

    if (item.isSkipped !== undefined) {
      this.isSkipped = item.isSkipped;
    }
    if (item.laborTotal !== undefined) {
      this.laborTotal = item.laborTotal;
    }
    if (item.previousAddress !== undefined) {
      this.previousAddress = item.previousAddress;
    }
    if (item.isPreviousAddressAdded !== undefined) {
      this.isPreviousAddressAdded = item.isPreviousAddressAdded;
    }
    if (item.isLoanApproved !== undefined) {
      this.isLoanApproved = item.isLoanApproved;
    }
    if (item.rmrAmount !== undefined) {
      this.rmrAmount = item.rmrAmount;
    }
    if (item.numberOfMonths !== undefined) {
      this.numberOfMonths = item.numberOfMonths;
    }
    if (item.loanAmount !== undefined) {
      this.loanAmount = item.loanAmount;
    }
    if (item.isMailSent !== undefined) {
      this.isMailSent = item.isMailSent;
    }
    if (item.documentId !== undefined) {
      this.documentId = item.documentId;
    }
    if (item.isLendproAttempted !== undefined) {
      this.isLendproAttempted = item.isLendproAttempted;
    }
    if (item.submittedFortivaLoanAmount !== undefined) {
      this.submittedFortivaLoanAmount = item.submittedFortivaLoanAmount;
    }
    if (item.fortivaProducts !== undefined) {
      this.fortivaProducts = item.fortivaProducts;
    }
    if (item.paymentId !== undefined) {
      this.paymentId = item.paymentId;
    }
    if (item.creditLimit !== undefined) {
      this.creditLimit = item.creditLimit;
    }
    if (item.lendProStatus !== undefined) {
      this.lendProStatus = item.lendProStatus;
    }
    if (item.display_name !== undefined) {
      this.display_name = item.display_name;
    }
    if (item.calendlyGenerated !== undefined) {
      this.calendlyGenerated = item.calendlyGenerated;
    }
    if (item.calendlyUrl !== undefined) {
      this.calendlyUrl = item.calendlyUrl;
    }
    if (item.source !== undefined) {
      this.source = item.source;
    }
    if (item.calendlyRetry !== undefined) {
      this.calendlyRetry = item.calendlyRetry;
    }
    if (item.isDemo !== undefined) {
      this.isDemo = item.isDemo;
    }
    if (item.isShowroom !== undefined) {
      this.isShowroom = item.isShowroom;
    }
    if (item.showRoomMonthlyTerm !== undefined) {
      this.showRoomMonthlyTerm = item.showRoomMonthlyTerm;
    }
    if (item.partner_id !== undefined) {
      this.partner_id = item.partner_id;
    }
    if (item.taskId !== undefined) {
      this.taskId = item.taskId;
    }
    if (item.taskScheduleFailMessage !== undefined) {
      this.taskScheduleFailMessage = item.taskScheduleFailMessage;
    }

    if (item.taskScheduleTitle !== undefined) {
      this.taskScheduleTitle = item.taskScheduleTitle;
    }

    if (item.isTaskScheduled !== undefined) {
      this.isTaskScheduled = item.isTaskScheduled;
    }

    if (item.taskScheduleDate !== undefined) {
      this.taskScheduleDate = item.taskScheduleDate;
    }
    if (item.isRenewal !== undefined) {
      this.isRenewal = item.isRenewal;
    }
    if (item.isReset !== undefined) {
      this.isReset = item.isReset;
    }
    if (item.resetOn !== undefined) {
      this.resetOn = item.resetOn;
    }
    if (item.timezoneOffset !== undefined) {
      this.timezoneOffset = item.timezoneOffset;
    }
    if (item.docusignPrefillInfo !== undefined) {
      this.docusignPrefillInfo = item.docusignPrefillInfo;
    }
    if (item.docusignPaymentInfo !== undefined) {
      this.docusignPaymentInfo = item.docusignPaymentInfo;
    }
    if (item.payment_data !== undefined) {
      this.payment_data = item.payment_data;
    }
    if (item.docusign_prefill_time !== undefined) {
      this.docusign_prefill_time = item.docusign_prefill_time;
    }

    if (item.summaryDocumentId !== undefined) {
      this.summaryDocumentId = item.summaryDocumentId;
    }
    if (item.isPhoneSales !== undefined) {
      this.isPhoneSales = item.isPhoneSales;
    }

    if (item.projected_close_date !== undefined) {
      this.projected_close_date = item.projected_close_date;
    }

    if (item.isIntroductoryOffer !== undefined) {
      this.isIntroductoryOffer = item.isIntroductoryOffer;
    }
    if (item.initialStageId !== undefined) {
      this.initialStageId = item.initialStageId;
    }
    if (item.paymentFailureMessage !== undefined) {
      this.paymentFailureMessage = item.paymentFailureMessage;
    }
    if (item.schedulerType !== undefined) {
      this.schedulerType = item.schedulerType;
    }
    if (item.restartPaymentAmount !== undefined) {
      this.restartPaymentAmount = item.restartPaymentAmount;
    }
    if (item.paidAmount !== undefined) {
      this.paidAmount = item.paidAmount;
    }

    if (item.docuAdditionalEquipment !== undefined) {
      this.docuAdditionalEquipment = item.docuAdditionalEquipment;
    }

    if (item.docuPaymentViaLendPro !== undefined) {
      this.docuPaymentViaLendPro = item.docuPaymentViaLendPro;
    }

    if (item.docuPaymentViaAci !== undefined) {
      this.docuPaymentViaAci = item.docuPaymentViaAci;
    }

    if (item.docuAddOnTotal !== undefined) {
      this.docuAddOnTotal = item.docuAddOnTotal;
    }

    if (item.docuPlanName !== undefined) {
      this.docuPlanName = item.docuPlanName;
    }

    if (item.docuTotalRmr !== undefined) {
      this.docuTotalRmr = item.docuTotalRmr;
    }

    if (item.docuMonthlyTax !== undefined) {
      this.docuMonthlyTax = item.docuMonthlyTax;
    }

    if (item.docuMonitoring !== undefined) {
      this.docuMonitoring = item.docuMonitoring;
    }
    if (item.payment_type !== undefined) {
      this.payment_type = item.payment_type;
    }

    if (item.is_ext_verified !== undefined) {
      this.is_ext_verified = item.is_ext_verified;
    }
    if (item.state_code !== undefined) {
      this.state_code = item.state_code;
    }

    if (item.branch !== undefined) {
      this.branch = item.branch;
    }

    if (item.isHomeOwner !== undefined) {
      this.isHomeOwner = item.isHomeOwner;
    }

    if (item.isNewGenFlow !== undefined) {
      this.isNewGenFlow = item.isNewGenFlow;
    }
    if (item.odooSchedulerErrorCount !== undefined) {
      this.odooSchedulerErrorCount = item.odooSchedulerErrorCount;
    }
    if (item.isRemoveRepSign !== undefined) {
      this.isRemoveRepSign = item.isRemoveRepSign;
    }
    if (item.isAmex !== undefined) {
      this.isAmex = item.isAmex;
    }
    if (item.amexServiceFee !== undefined) {
      this.amexServiceFee = item.amexServiceFee;
    }
    if (item.fundingAccountSubType !== undefined) {
      this.fundingAccountSubType = item.fundingAccountSubType;
    }
    if (item.fundingAccount !== undefined) {
      this.fundingAccount = item.fundingAccount;
    }
    if (item.billingAccountNumber !== undefined) {
      this.billingAccountNumber = item.billingAccountNumber;
    }
    if (item.fullPaymentApplied !== undefined) {
      this.fullPaymentApplied = item.fullPaymentApplied;
    }
    if (item.paymentDate !== undefined) {
      this.paymentDate = item.paymentDate;
    }
    if (item.customerSecondaryInfo !== undefined) {
      this.customerSecondaryInfo = item.customerSecondaryInfo;
    }
    if (item.equifaxCreditScore !== undefined) {
      this.equifaxCreditScore = item.equifaxCreditScore;
    }
    if (item.branchName !== undefined) {
      this.branchName = item.branchName;
    }
    if (item.fullAddress !== undefined) {
      this.fullAddress = item.fullAddress;
    }
    if (item.loanType !== undefined) {
      this.loanType = item.loanType;
    }
    if (item.lendfoundryRequestedAmt !== undefined) {
      this.lendfoundryRequestedAmt = item.lendfoundryRequestedAmt;
    }
    if (item.loanAppCid !== undefined) {
      this.loanAppCid = item.loanAppCid;
    }
    if (item.odooApplicationId !== undefined) {
      this.odooApplicationId = item.odooApplicationId;
    }
    if (item.lfLoanAmountSubmitted !== undefined) {
      this.lfLoanAmountSubmitted = item.lfLoanAmountSubmitted;
    }
    if (item.lfAttempted !== undefined) {
      this.lfAttempted = item.lfAttempted;
    }
    if (item.remoteLendfoundryInfo !== undefined) {
      this.remoteLendfoundryInfo = item.remoteLendfoundryInfo;
    }
    if (item.submittedLendfoundryLoanAmount !== undefined) {
      this.submittedLendfoundryLoanAmount = item.submittedLendfoundryLoanAmount;
    }
    if (item.rmrListPrice !== undefined) {
      this.rmrListPrice = item.rmrListPrice;
    }
    if (item.rmrSalePrice !== undefined) {
      this.rmrSalePrice = item.rmrSalePrice;
    }
    if (item.isRmrIncludedInPayment !== undefined) {
      this.isRmrIncludedInPayment = item.isRmrIncludedInPayment;
    }
    if (item.paymentResultInfo !== undefined) {
      this.paymentResultInfo = item.paymentResultInfo;
    }
    if (item.signData !== undefined) {
      this.signData = item.signData;
    }
    if (item.mobile !== undefined) {
      this.mobile = item.mobile;
    }
    if (item.formattedPhone !== undefined) {
      this.formattedPhone = item.formattedPhone;
    }
    if (item.isVirtualAppointment !== undefined) {
      this.isVirtualAppointment = item.isVirtualAppointment;
    }
    if (item.isRemoteAciPaymentDone !== undefined) {
      this.isRemoteAciPaymentDone = item.isRemoteAciPaymentDone;
    }
    if(item.markAsPaidInfo !== undefined){
      this.markAsPaidInfo = item.markAsPaidInfo;
    }
    if (item.isConvenienceFeeAdded !== undefined) {
      this.isConvenienceFeeAdded = item.isConvenienceFeeAdded;
    }
    if (item.showFireProtection !== undefined) {
      this.showFireProtection = item.showFireProtection;
    }
    if(item.isOnitHome !== undefined){
      this.isOnitHome = item.isOnitHome
    }
    if (item.showFireProtection !== undefined) {
      this.showFireProtection = item.showFireProtection;
    }
    if (item.includeTax !== undefined) {
      this.includeTax =item.includeTax;
    }
    if (item.creditCheckId !== undefined) {
      this.creditCheckId = item.creditCheckId;
    }
    if(item.isCreditFrozen != undefined){
      this.isCreditFrozen = item.isCreditFrozen;
    }
    if (item.ownerType !== undefined) {
      this.ownerType = item.ownerType;
    }
    if (item.loanApplicationsActions !== undefined) {
      this.loanApplicationsActions = item.loanApplicationsActions;
    }
    if (item.preScreenId !== undefined) {
      this.preScreenId = item.preScreenId;
    }
    if (item.preScreenCreatedAt !== undefined) {
      this.preScreenCreatedAt = item.preScreenCreatedAt;
    }
    if (item.preScreenStatus !== undefined) {
      this.preScreenStatus = item.preScreenStatus;
    }
    if (item.preApprovedAmount !== undefined) {
      this.preApprovedAmount = item.preApprovedAmount;
    }
    if (item.preScreenProvider !== undefined) {
      this.preScreenProvider = item.preScreenProvider;
    }
    if (item.preScreenRejected !== undefined) {
      this.preScreenRejected = item.preScreenRejected;
    }
  }
}