import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import * as xml2js from 'xml2js';
import { CustomerNewService } from './customer-new.service';
import { preScreenInfo, preScreenStatus } from 'app/configs/prescreen';
import * as moment from 'moment';
import { environment } from 'environments/environment';
import { ConfigurationService } from './configuration.service';
import { PrescreenService } from './prescreen.service';

@Injectable()
export class HartService {

  constructor(
    private _http: HttpClient,
    private _prescreenService: PrescreenService,
    private _configurationService: ConfigurationService
  ) { }

  async prescreenCustomer(customerOdooId,payloadData,remote='') {
    const borrowerDetail = payloadData.borrowers[0];
    const hartConfigData = await this._configurationService.getConfiguration('hart', remote);
    console.log("hartConfigData",hartConfigData);
    const body = new URLSearchParams({
      ACCOUNT:environment.HART_ACCOUNT,
      PASSWD: environment.HART_PASSWORD,
      PASS: hartConfigData.pass,
      PROCESS:hartConfigData.process,
      BUREAU:hartConfigData.bureau,
      SPLITNAME:'1',
      NAMEFIRST: borrowerDetail.first_name,
      NAMELAST: borrowerDetail.last_name,
      DOB: borrowerDetail.date_of_birth,
      ADDRESS: borrowerDetail.line1,
      CITY: borrowerDetail.city,
      STATE: borrowerDetail.state,
      ZIP: borrowerDetail.zip_code,
      PREVADDRESS: '',
      PREVCITY: '',
      PREVSTATE: '',
      PREVZIP: '',
      ADCADVANCE_MERCHANTID:hartConfigData.adc,
      NAME_OF_REPRESENTATIVE:hartConfigData.rep_name,
      EMAIL_OF_REPRESENTATIVE:hartConfigData.rep_mail
    }).toString();

    return this._http
      .post(`/api/hart`, body, {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
        responseType: 'text'
      })
      .pipe(
        map( async (response) =>{
          const parser = new xml2js.Parser();
          const parsedResponse = await parser.parseStringPromise(response);
          const date = moment(new Date(), 'YYYY-MM-DD HH:mm:ss');
          const preScreenCreatedAt = date.utc();
          let hartStatus = preScreenStatus.fail,hartPreApprovedAmount = 0, hartId = 0;
          if(parsedResponse && parsedResponse.AdcAdvanceResults){
            const result = parsedResponse.AdcAdvanceResults;
            const transId = result.TransId && Number(result.TransId[0]);
            const decisionData = result.DecisionData && result.DecisionData[0];
            if(decisionData){
              const descisionType = decisionData.DecisionType && (decisionData.DecisionType[0]).toLowerCase();
              if(transId && descisionType === preScreenStatus.success){
                const creditLimit = Number(decisionData.CreditLimit[0]);
                hartPreApprovedAmount = creditLimit;
                hartId = transId;
                hartStatus = descisionType;
              }
              else if(descisionType){
                const creditLimit = Number(decisionData.CreditLimit[0]);
                hartPreApprovedAmount = creditLimit;
                hartStatus = preScreenStatus.fail;
                hartId = transId;
              }
              await this._prescreenService.addPrescreenDetails(
                {
                  preScreenId: hartId,
                  preApprovedAmount: hartPreApprovedAmount,
                  preScreenStatus: hartStatus,
                  preScreenCreatedAt : preScreenCreatedAt.toISOString(),
                  preScreenProvider: preScreenInfo.preScreenProvider,
                  odooId: customerOdooId,
                },
                remote
              )
            }
          }
          return {
            preScreenId: hartId,
            preApprovedAmount: hartPreApprovedAmount,
            preScreenStatus: hartStatus,
            preScreenCreatedAt : preScreenCreatedAt.toISOString(),
            preScreenProvider: preScreenInfo.preScreenProvider
          }
        })
      ).toPromise();
  }
}
