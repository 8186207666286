import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import Bugsnag from '@bugsnag/js';
import { CustomerNewService } from '../services/customer-new.service';
import { preScreenInfo, preScreenStatus } from 'app/configs/prescreen';

@Injectable()
export class PreScreenGuard {
  hasPrescreening: boolean = preScreenInfo.hasPrescreening;
  paymentMode: string = '';
  constructor(
    private _customerService: CustomerNewService,
    private _router: Router
  ) {}

  OnDestroy() {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(async (resolve, reject) => {
      try {
        const _customerGuid = route.params?.guid;
        const _odooCustomerId = route.params?.odooCustomerId;
        this.paymentMode = route.params?.paymentMode;
        if (this.hasPrescreening && this.paymentMode === 'finance') {
          const currentCustomer = await this._customerService.getCustomerByGuid(
            _customerGuid,
            'remote',
          );
          if (
            this.hasPrescreening &&
            currentCustomer.preScreenProvider === preScreenInfo.preScreenProvider &&
            (
              currentCustomer.preScreenStatus === preScreenStatus.fail ||
              (
                currentCustomer.preScreenStatus === preScreenStatus.success && 
                currentCustomer.preScreenRejected
              )
            )
          ) {
            this._router.navigateByUrl(
              `/auth/remote/payment/cash/${_customerGuid}/${_odooCustomerId}`,
            );
          }else{
            return resolve(true);
          }
        } else {
          return resolve(true);
        }
      } catch (error) {
        console.log('PreScreen Guard Error', error);
        Bugsnag.notify(error);
        return resolve(true);
      }
    });
  }
}
