export const environment = {
  production: false,
  stage: 'uat',
  odoo: {
    DATABASE: 'uat12'
  },
  USER_ODOO_GROUP: 'SalesPro User',
  MANAGER_ODOO_GROUP: 'SalesPro Manager',
  ADMIN_ODOO_GROUP: 'SalesPro Admin',
  TRIMMED_LEAD_ID: 4,
  SELF_GEN_LEAD_ID: 28,
  TEMPLATE: {
    '2020 HP Alarm Contract (fortiva)': '70c24451-4f20-4b5b-9ef1-ded8b27c23e6',
    '2020 HP Alarm Contract (Smartpay)': '26d724de-97a8-4e2a-9623-5f3a62a9a45c',
    '2018 HP Alarm Contract (app)': 'fec5d31c-6776-4833-9cab-03d7ed95774e',
    '2021 HP Alarm Contract (show room)':'637d9b0e-3852-41fb-baf0-53eb31526672',
    '2021 HP Alarm Contract (3g Renewal)':'e8e7b24b-b392-441a-b888-daa97da06656'
  },
   SHOWROOM_ODOO_GROUP:'SalesPro - Pre-Select Sales',
   PRE_SELECT_SALES_GROUP:139,
   RENEWAL_ODOO_USER_GROUP_NAME:'SalesPro - 3G upgrades',
   RENEWAL_GROUP : 155,
   RENEWAL_GROUP_1: 154,
   RENEWAL_LIVE_1: 153,
   PRESELECT_RENEWAL_REMOTE_GROUP:['SalesPro Admin','SalesPro - Pre-Select Sales','SalesPro - 3G upgrades'],
   PHONE_SALES_ODOO_USER_GROUP_NAME:'SalesPro - Phone Sales',
   GOOGLE_BUILDER_CLOUD_BUCKET_URL:'https://storage.googleapis.com/builders-docs/',
   NEW_SELFGEN_FLOW:true,
   REMOVE_REP_SIGN: false,
   ENABLE_FULL_PAYMENT_AFTER_FINANCE_APPROVED: false,
   MERGE_CONTRACT_AND_SUMMARY : true,
   ENABLE_PREVIOUS_ADDRESS: true,
   ENABLE_LEND_FOUNDRY: true,
   SYNC_ASSOCIATION:false,
   HAS_LENDFOUNDRY:'SalesPro - Smart Home Pay (Lendfoundry)',
   SHOW_LF_REMOTE:true,
   API_URL: 'https://spback-uat-fpsfl7iifq-uc.a.run.app/',
   HAS_CREDIT_CHECK: {
      'Trimmed': true,
      'Renewal': true,
      'Show Room': false,
      'Phone Sales': true,
      'Self Gen': true
    },
    ONIT_HOME_ODOO_GROUP:'SalesPro - OnIt Home',
    ONIT_HOME_ODOO_GROUP_ID:'218',
    ONIT_HOME_EXTERNAL_ODOO_GROUP:'SalesPro - OnIt External User',
    SHOW_GOOGLE_LOGIN:true,
    PROCESSING_FEE:3,
    SALESPRO_ADMIN:'salespro.admin@homeprotech.com',
    EXCLUDE_IO_PACKAGES:['SP505'],
    CREDIT_SCORE_PROVIDER:'isoftpull',
    ISOFTPULL_DISAGREE_PROCEED:true ,
    NOTIFIER_TEXT :'You are currently using UAT environment',
    API_VERSION : '2025.01.02',
    DEFAULT_CREDIT_SCORE: '640',
    RUN_NEW_CREDIT_POLICY: true,
    FINANCE_PROVIDER: 'SHP',
    HAS_PRESCREENING: true,
    PRESCREEN_PROVIDER: 'HART',
    HART_ACCOUNT: 'STAGINGPRESCREEN',
    HART_PASSWORD: 'tbS$tlu-PHOO0Cz7Jl.Y',
    HAS_EQUIPMENT_TYPE : false,
};
